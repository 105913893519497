<template>
  <div>
    <img @click="toggle(true)" :class="`${styling} cursor-pointer`" :src="require(`../assets/images/${path}`)" :alt="alt">
    <div @click="toggle(false)" :class="`${ active ? 'opacity-1' : 'opacity-0 no-pointer' } ease-5 p-25 border-box`" id="overlay">
      <img class="w-75p mb:w-full h-full object-contain" :src="require(`../assets/images/${path}`)" :alt="alt">
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'path',
    'alt',
    'styling'
  ],
  data() {
    return {
      active: false
    }
  },
  methods: {
    toggle(val) {
      this.active = this.isMobile() ? false : val;
    },
    isMobile() {
      return window.matchMedia('(max-width: 768px)').matches;
    }
  }
}
</script>

<style scoped>
#overlay {
  position: fixed; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); 
  z-index: 2;
  cursor: pointer;
}
img {
  -webkit-tap-highlight-color: transparent;
}
</style>