<template>
  <div>
    <SectionHeader title="Seneca Light &amp; Water - Website Redesign"/>
    <div class="m-x-15p mb:m-25">
      <div class="flex flex-col gap-20 p-x-50 mb:p-x-0 mb-40">
        <p><span class="font-robBold">Challenge:</span> Redesign the Seneca Light &amp; Water website into a responsive design that offers users an intuitive navigation experience.</p>
        <p><span class="font-robBold">Deliverables:</span> Strategy, User research report, affinity map, personas, information architecture, Lo-Fi sketches, UI, Usability Study, and Hi-Fi prototype.</p>
        <p><span class="font-robBold">Role:</span> Product Designer</p>
      </div>
      <img src="@/assets/images/seneca/sen-main.png" alt="" class="w-full mb-40">
    </div>
    <div class="m-x-15p mb:m-25">
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Goals and Objectives</h1>
        <p class="mt-20">Redesign the Seneca Light &amp; Water website into a responsive design that has intuitive navigation, readability, a strong brand identity, and a clear call to action.</p>
        <h1 class="font-barBold font-24 mt-40 mb-20">Challenge</h1>
        <ul class="mt-0 mb-40">
          <li class="mb-10">The Seneca Light &amp; Water&apos;s current website has a cluttered layout, poor readability, hard to find buttons, an unclear brand, and causes the user to become frustrated.</li>
        </ul>
        <h1 class="font-barBold font-24 mt-40 mb-20">Process</h1>
        <p class="mb-20">Starting from the user research phase, I first evaluated the current website to determine what I was working with, this helped me find out what kinds of questions I would need to ask users to gather additional insight. Since there was no business or demographic information for the company, I turned to the most recent census reports for the city of Seneca, SC to find my key demographics. Using this information, I selected two current Seneca Light &amp; Water users and two other users within the key  demographics to discuss their experiences with utility websites and then to participate in a usability study with the current website.</p>
        <p class="mb-20">During the synthesis phase, I created an affinity diagram to sort my findings into four main user pain points. I created two user personas to increase empathy throughout the entire project and to buy stakeholder buy-in.</p>
        <p class="">I also created a website map to ensure the information architecture is intuitive for the user, as it was a major user pain point. I created Lo-Fi sketches and a Hi-Fi prototype in Adobe XD. By the end, I was able to validate that the user pain points had been solved by the designs. The project timeline was 3 weeks.</p>
      </div>
      <div class="flex-center">
        <img src="@/assets/images/seneca/sen-process.png" alt="" class="m-w-1000 w-full">
      </div>
    </div>
    <hr class="w-650 m-y-0 h-0 border-top-gray mb:w-80p">
    <SectionHeader title="RESEARCH"/>
    <div class="m-x-15p mb:m-25">
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Preliminary Research</h1>
        <p class="m-y-20">Since there was no demographic information for Seneca Light and Water, I turned to the most recent census reports for the city of Seneca, SC. Using these reports, I found that my key demographic will be female renters between the ages of 35-54 and male homeowners between the ages of 55-64.</p>
        <p class="mb-10">Report one: <a class="word-break" href="https://worldpopulationreview.com/us-cities/seneca-sc-population" target="_blank">https://worldpopulationreview.com/us-cities/seneca-sc-population</a></p>
        <p class="mb-20">Report two: <a class="word-break" href="https://www.neighborhoodscout.com/sc/seneca/demographics" target="_blank">https://www.neighborhoodscout.com/sc/seneca/demographics</a></p>
      </div>
      <div class="flex-center">
        <img src="@/assets/images/seneca/sen-find5.png" alt="" class="w-full m-w-1000 mb-20">
      </div>
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">User Research</h1>
        <p class="m-y-20">Using my preliminary research, I selected two current Seneca Light and Water users and two other users within my key demographic to discuss their experiences with utility companies and then to participate in a usability study using the current Seneca Light and Water website.</p>
      </div>
      <div class="flex-center">
        <img src="@/assets/images/seneca/sen-all.png" alt="" class="w-full m-w-750 mb-20">
      </div>
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Key Findings</h1>
        <p class="mt-20">I received a lot of insightful information on the uses of a utility website, how users interact with the current site, and opportunities for improvement. I sorted all this information using an affinity map to find trends.</p>

        <p class="mt-20">I discovered that the main use of this website is to pay your bill, which is very important for both users and the company. Unfortunately, this was one of the most apparent pain points for all users that were tested. Additionally, users struggled with navigating the website, reading content due to the overwhelming use of colored text, and didn&amp;t understand the meaning of the photos used on the site. 4 of the 4 users were very frustrated and annoyed during the usability study of the current site.</p>

        <p class="mt-20 font-barBoldItalic font-16 text-center">“It feels like everything is screaming at me.” ~ Usability Participate</p>
      </div>
      <div class="grid grid-4-col md:grid-2-col mb:grid-1-col mt-20">
        <img src="@/assets/images/seneca/sen-find1.png" alt="" class="w-full">
        <img src="@/assets/images/seneca/sen-find2.png" alt="" class="w-full">
        <img src="@/assets/images/seneca/sen-find3.png" alt="" class="w-full">
        <img src="@/assets/images/seneca/sen-find4.png" alt="" class="w-full">
      </div>

      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Personas</h1>
        <p class="mt-20 mb-40">After gathering and sorting my data, I used my research information to create two user personas that will help with stakeholder buy-in and empathy throughout the entire redesign process.</p>
      </div>

      <div class="flex-center-col">
        <img src="@/assets/images/seneca/sen-james.png" alt="" class="w-full m-w-750 mb-20">
        <img src="@/assets/images/seneca/sen-mia.png" alt="" class="w-full m-w-750 mb-20">
      </div>
    </div>
    <hr class="w-650 m-y-0 h-0 border-top-gray mb:w-80p">
    <SectionHeader title="IDEATION"/>
    <div class="m-x-15p mb:m-25">
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Information Architecture</h1>
        <p class="mt-20 mb-40">Since navigation was a main user pain point, I focused on creating a clear website layout of the current content to create a more intuitive experience.</p>
      </div>
      <div class="flex-center">
        <img src="@/assets/images/seneca/sen-ia.png" alt="" class="w-full m-w-1000 mb-20">
      </div>
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Lo-Fi Sketches</h1>
        <p class="mt-20 mb-40">To start ideation, I reviewed my personas, research findings, and the information architecture to ensure all sketches are keeping the end user in mind. I started with a website first approach and will create the mobile screens after the final designs to ensure a responsive experience.</p>
      </div>
      <div class="grid grid-2-col mb:grid-1-col mt-20 gap-50 mb:gap-10">
        <img src="@/assets/images/seneca/sen-ske1.png" alt="" class="m-w-350 m-auto w-full">
        <img src="@/assets/images/seneca/sen-ske2.png" alt="" class="m-w-350 m-auto w-full">
        <img src="@/assets/images/seneca/sen-ske4.png" alt="" class="m-w-350 m-auto w-full">
        <img src="@/assets/images/seneca/sen-ske5.png" alt="" class="m-w-350 m-auto w-full">
        <img src="@/assets/images/seneca/sen-ske6.png" alt="" class="m-w-350 m-auto w-full">
        <img src="@/assets/images/seneca/sen-ske7.png" alt="" class="m-w-350 m-auto w-full">
      </div>
      <div class="p-x-30 mb:p-x-0 m-y-50">
        <p class="mt-20 mb-10">Based on my research insights, I prioritized four main objectives:</p>
        <ul class="m-0">
          <li>Provide an intuitive navigation experience for users.</li>
          <li>Provide users with clear call-to-action buttons.</li>
          <li>Provide users with easy readability and scannability.</li>
          <li>Provide a clear brand identity.</li>
        </ul>
      </div>
    </div>
    <hr class="w-650 m-y-0 h-0 border-top-gray mb:w-80p">
    <SectionHeader title="PROTOTYPE"/>
    <div class="m-x-15p mb:m-25">
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Hi-Fi Prototype</h1>
        <p class="mt-20 mb-40">Using Adobe XD, I converted the Lo-Fi sketches into a Hi-Fi prototype. Below are the website comparisons showing the before and after changes. The current website is on the left, while the new redesign is on the right.</p>
      </div>
    </div>
    <hr class="w-650 m-y-0 h-0 border-top-gray mb:w-80p">
    <h1 class="font-24 mt-20 mb-40 font-barBold text-center m-x-25">HOME PAGE</h1>
    <div class="m-x-15p mb:m-25">
      <div class="grid grid-2-col mb:grid-1-col mt-20 gap-10">
        <OverlayImage alt="" path="seneca/sen-oldweb1.png" styling="w-full"/>
        <OverlayImage alt="" path="seneca/sen-home.png" styling="w-full"/>
      </div>
    </div>
    <hr class="w-650 m-y-0 h-0 border-top-gray mb:w-80p">
    <h1 class="font-24 mt-20 mb-40 font-barBold text-center m-x-25">ABOUT US</h1>
    <div class="m-x-15p mb:m-25">
      <div class="grid grid-2-col mb:grid-1-col mt-20 gap-10">
        <OverlayImage alt="" path="seneca/sen-oldweb2.png" styling="w-full"/>
        <OverlayImage alt="" path="seneca/sen-about.png" styling="w-full"/>
      </div>
    </div>
    <hr class="w-650 m-y-0 h-0 border-top-gray mb:w-80p">
    <h1 class="font-24 mt-20 mb-40 font-barBold text-center m-x-25">CONTACT US</h1>
    <div class="m-x-15p mb:m-25">
      <div class="grid grid-2-col mb:grid-1-col mt-20 gap-10">
        <OverlayImage alt="" path="seneca/sen-oldweb3.png" styling="w-full"/>
        <OverlayImage alt="" path="seneca/sen-contact.png" styling="w-full"/>
      </div>
    </div>
    <hr class="w-650 m-y-0 h-0 border-top-gray mb:w-80p">
    <h1 class="font-24 mt-20 mb-40 font-barBold text-center m-x-25">MY ACCOUNT</h1>
    <div class="m-x-15p mb:m-25">
      <div class="grid grid-2-col mb:grid-1-col mt-20 gap-10">
        <OverlayImage alt="" path="seneca/sen-oldweb4.png" styling="w-full"/>
        <OverlayImage alt="" path="seneca/sen-account.png" styling="w-full"/>
      </div>
    </div>
    <hr class="w-650 m-y-0 h-0 border-top-gray mb:w-80p">
    <h1 class="font-24 mt-20 mb-40 font-barBold text-center m-x-25">MEMBER SERVICES</h1>
    <div class="m-x-15p mb:m-25">
      <div class="flex-center">
        <OverlayImage alt="" path="seneca/sen-memberserv.png" styling="m-w-650 w-full"/>
      </div>
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24 mt-50">Clickable Prototype</h1>
        <p class="mt-20 mb-40">I created a <a href="https://xd.adobe.com/view/a22d8c9b-97d5-4e10-9874-58111bcec61c-f77e/?fullscreen&hints=off" target="_blank">clickable prototype</a> of the main website pages. This was used to test and validate the designs. The usability study gave me valuable insight into what users found intuitive and what could be improved further.</p>
      </div>

      <iframe class="w-full h-400 mb-50" style="border: 1px solid rgba(0, 0, 0, 0.1);" src="https://xd.adobe.com/embed/a22d8c9b-97d5-4e10-9874-58111bcec61c-f77e/?fullscreen&hints=off" allowfullscreen></iframe>
    </div>
    <hr class="w-650 m-y-0 h-0 border-top-gray mb:w-80p">
    <SectionHeader title="RESPONSIVE DESIGNS"/>
    <div class="m-x-15p mb:m-25">
      <div class="p-x-30 mb:p-x-0">
        <p class="mt-20 mb-40">To ensure the website is fully responsive, I created Hi-Fi mock-ups of the website on various screen sizes. This would allow users to access Seneca Light &amp; Water from most devices.</p>
      </div>
      <div class="grid grid-2-col mb:grid-1-col gap-10 mb-20">
        <img src="@/assets/images/seneca/sen-mod1.png" alt="" class="m-x-auto mb:w-full">
        <img src="@/assets/images/seneca/sen-mod2.png" alt="" class="m-x-auto mb:w-full">
      </div>
      <div class="grid grid-2-col mb:grid-1-col gap-10 mb-20">
        <img src="@/assets/images/seneca/sen-mod3.png" alt="" class="m-x-auto mb:w-full">
        <img src="@/assets/images/seneca/sen-mod4.png" alt="" class="m-x-auto mb:w-full">
      </div>
      <div class="flex-center">
        <img src="@/assets/images/seneca/sen-mod5.png" alt="" class="mb:w-full">
      </div>
    </div>
    <hr class="w-650 m-y-0 h-0 border-top-gray mb:w-80p">
    <SectionHeader title="VALIDATE"/>
    <div class="m-x-15p mb:m-25">
      <div class="flex-center">
        <img src="@/assets/images/seneca/sen-val.png" class="m-h-285 mb:w-full mb:m-h-reset" alt="">
      </div>
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">Conclusion</h1>
        <p class="m-y-20">By using and analyzing all of my research and designing items, I was able to create a Hi-Fi prototype of the website and validated my designs. Using the clickable prototype with four participates, I received the following data:</p>
        <p class="mt-20 mb-10">Below is the results from our Hi-Fi usability study:</p>
        <ul class="mt-0 mb-40">
          <li>(75% increase) 4 out of 4 users were able to successfully pay their bill.</li>
          <li>(50% increase) 4 out of 4 users were able to successfully send the company an email.</li>
          <li>(50% increase) 3 out of 4 users were able to find the associations list.</li>
          <li>(50% increase) 4 out of 4 users intuitively knew how to report an outage.</li>
        </ul>
      </div>
    </div>
    <hr class="w-650 m-y-40 border-top-gray mb:w-80p">
    <div class="m-x-15p mb:m-25">
      <div class="p-x-30 mb:p-x-0">
        <h1 class="font-barBold font-24">After Thoughts</h1>
        <p class="m-y-20">This UX case study was very insightful on the realm of web design and a great learning experience. I found it quite overwhelming at first as to how I was going to fill up the space of a website with content, as it&apos;s much larger than a phone screen. By referencing back to my research and focusing on the users pain points, I was able to make a list of content they needed before I started sketching designs. I also found my sitemap to be extremely help in making sure each screen was connected in a way that made sense. In the end, I feel that I was able to effectively lay out the content and fill out the webpage in a way that flowed naturally.</p>
        <p class="mb-20">This project showed me the importance of laying what the items you need to include and information architecture before starting any designs. </p>
        <p class="mb-20 font-robItalic">Note: I do not work for, nor am I affiliated with Seneca Light & Water. This UX study was done as a learning experience to explore different types of products and how to improve them.</p>
        <p class="mb-50 font-robItalic">*Icons were supplied by icons8.com.</p>
      </div>
    </div>
  </div>
</template>

<script>  
import SectionHeader from '../components/SectionHeader.vue'
import OverlayImage from '../components/OverlayImage.vue'
export default {
  components: {
    SectionHeader,
    OverlayImage
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>

</style>
